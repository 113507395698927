
import { defineComponent, ref } from "vue";
import { Download } from "@element-plus/icons-vue";
import exportFromJSON from "export-from-json";

import ApiService from "@/core/services/ApiService";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { ILambdaFunctionInvocationInfo } from "@/models/lambda/ILambdaFunctionInvocationInfo";
import { Search } from "@element-plus/icons-vue";
import { ElMessage } from "element-plus";

interface list {
  fn_name: string;
  total_invocation: number;
}

export default defineComponent({
  name: "top-invoked-lambda-function-list-widget",
  components: {
    Datatable,
    Download,
    Search,
  },
  props: {
    widgetClasses: String,
  },
  data: function () {
    return {
      headers: [
        {
          name: "Function Name",
          key: "fn_name",
          sortable: true,
        },
        {
          name: "Total Invocation",
          key: "total_invocation",
          sortable: true,
        },
      ],
      functionList: ref<Array<ILambdaFunctionInvocationInfo>>([]),
      initialData: ref<Array<ILambdaFunctionInvocationInfo>>([]),
      searchInput: ref(""),
      dateFilter: ref<Array<string>>([]),
    };
  },
  mounted: function () {
    this.getList();
  },
  methods: {
    getList: function () {
      ApiService.vueInstance.axios
        // .get("database/v1/datadog/lambda/top-invocation") // prod current
        .get(
          "datadog/v1/lambda/top-invocation?limit=100&sort=desc&end_date=1643648400&start_date=1638291600" // dev current
        )
        .then((response) => {
          this.functionList.splice(
            0,
            response.data.data.length,
            ...response.data.data
          );
          this.initialData.splice(
            0,
            this.functionList.length,
            ...this.functionList
          );
        });
    },
    searchService: function () {
      let filteredlist: list[] = this.initialData.filter((el) => {
        return el.fn_name.includes(this.searchInput);
      });
      this.functionList.splice(0, this.initialData.length, ...filteredlist);
    },
    disabledDate: function (time: Date) {
      return time.getTime() > Date.now();
    },
    onDownloadCsv() {
      const uniqTime = (new Date().getTime() / 1000).toFixed(0);
      const exportType = exportFromJSON.types.csv;
      let fileName = `top_invoked_list_function_${uniqTime}`;
      if (this.searchInput) fileName = `${this.searchInput}_${fileName}`;
      const data = this.functionList.map((el, idx) => {
        return {
          "No.": idx + 1,
          "Function Name": el.fn_name,
          "Total Invocation": el.total_invocation,
        };
      });

      if (data?.length > 0) exportFromJSON({ data, fileName, exportType });
    },
  },
  computed: {
    getServerList: function () {
      this.searchService();
      return this.functionList;
    },
  },
  watch: {
    dateFilter(newData) {
      const startDate = parseInt(
        (new Date(newData[0]).getTime() / 1000).toFixed(0)
      );
      const endDate = parseInt(
        (new Date(newData[1]).getTime() / 1000).toFixed(0)
      );
      let url = `datadog/v1/lambda/top-invocation?limit=100&sort=desc&end_date=${endDate}&start_date=${startDate}`; // dev current
      ApiService.vueInstance.axios
        .get(url)
        .then((response) => {
          this.functionList.splice(
            0,
            this.functionList.length,
            ...response.data.data
          );
        })
        .catch((error) => {
          if (error instanceof Error) {
            // ✅ TypeScript knows err is Error
            ElMessage.error(error.message);
          } else {
            ElMessage.error("Unexpected error");
          }
        });
    },
  },
});
