
import { defineComponent, onMounted } from "vue";
import ServerList from "@/components/widgets/tables/ServerList.vue";
import TopInvokedLambdaFunctionList from "@/components/widgets/tables/TopInvokedLambdaFunctionList.vue";
import UpdateLambdaWidget from "@/components/widgets/mixed/UpdateLambdaWidget.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "dashboard",
  components: {
    ServerList,
    TopInvokedLambdaFunctionList,
    UpdateLambdaWidget,
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Dashboard");
    });
  },
});
