
import { defineComponent, ref } from "vue";
import { IUpdateLambda } from "@/models/server/IUpdateLambda";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ApiService from "@/core/services/ApiService";
import { AxiosRequestConfig } from "axios";
import { ElNotification } from "element-plus";

export default defineComponent({
  name: "update-lambda-widget",
  data() {
    return {
      formData: ref<IUpdateLambda>({
        fn_name_wildcard: "",
      }),
      loading: ref<boolean>(false),
      rules: {
        fn_name_wildcard: [
          {
            required: true,
            message: "Function wildcard is required!",
            trigger: "change",
          },
        ],
      },
      dialogVisible: ref<boolean>(false),
      affectedLambdaFunction: ref([] as string[]),
    };
  },
  methods: {
    showInfoModal() {
      Swal.fire({
        title: "Lamba Updater",
        text: "This tool is used to update lambda function(s) based on your input. Do NOTE that the input is a wildcard!",
        icon: "info",
        buttonsStyling: false,
        confirmButtonText: "Ok, got it!",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        closeOnClickOutside: true,
      });
    },
    showUpdateConfirmationModal() {
      this.loading = true;
      ApiService.query("lambda/v1/function/list", {
        params: { fn_name_wildcard: this.formData.fn_name_wildcard },
      })
        .then((response) => {
          const shownData = response.data.data.map((el, idx) => {
            return {
              index: idx + 1,
              functionName: el,
            };
          });
          this.affectedLambdaFunction = shownData;
          this.dialogVisible = true;
          this.loading = false;
        })
        .catch((err) => {
          Swal.fire({
            title: err.response.data.message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          this.loading = false;
        });
    },
    cancleUpdate() {
      this.dialogVisible = false;
    },
    async update() {
      try {
        await ApiService.post("lambda/v1/function/update", {
          fn_name_wildcard: this.formData.fn_name_wildcard,
        } as AxiosRequestConfig);
        ElNotification({
          title: "Success",
          message: "Update Shown function success.",
          type: "success",
        });
        this.dialogVisible = false;
      } catch (error) {
        ElNotification({
          title: "Failed",
          message: "Update Shown function failed.",
          type: "error",
        });
      }
    },
  },
  props: {
    widgetClasses: String,
  },
  setup() {
    return {};
  },
});
