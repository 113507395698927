
import ApiService from "@/core/services/ApiService";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { IServer } from "@/models/server/IServer";
import { defineComponent, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";

// Import Component
import AddServerModal from "@/components/modals/forms/AddServerModal.vue";

export default defineComponent({
  name: "server-list-widget",
  components: {
    Datatable,
    AddServerModal,
  },
  props: {
    widgetClasses: String,
  },
  data: function () {
    return {
      headers: [
        {
          name: "Name",
          key: "name",
          sortable: true,
        },
        {
          name: "Address",
          key: "address",
          sortable: true,
        },
        {
          name: "Username",
          key: "username",
          sortable: true,
        },
      ],
      servers: ref<Array<IServer>>([]),
      initialData: ref<Array<IServer>>([]),

      search: ref<string>(""),
      serverGroup: ref<number>(0),
    };
  },
  mounted: function () {
    this.getServerList(0);
  },
  methods: {
    getServerList(stage: number | undefined) {
      let urlEndpoint = `database/v1/server/list?stage=`;
      if (stage) urlEndpoint += stage;
      ApiService.vueInstance.axios.get(urlEndpoint).then((response) => {
        this.servers.splice(0, this.servers.length, ...response.data.data);
        this.initialData.splice(0, this.servers.length, ...this.servers);
      });
    },
    addNewServer: function (newServer: IServer) {
      ApiService.vueInstance.axios
        .post("database/v1/server/add", newServer)
        .then(() => {
          this.getServerList(0);
        })
        .catch((err) => {
          Swal.fire({
            title: err.response.data.message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        });
    },
    keyMatches(obj, value): boolean {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].toLowerCase().indexOf(value.toLowerCase()) != -1) {
            return true;
          }
        }
      }
      return false;
    },
    searchServer() {
      this.servers.splice(0, this.servers.length, ...this.initialData);
      if (this.search !== "") {
        const results = this.servers.filter((server) => {
          return this.keyMatches(server, this.search);
        });
        this.servers.splice(0, this.servers.length, ...results);
      }
    },
  },
  watch: {
    async serverGroup(newItem) {
      this.getServerList(newItem);
    },
  },
});
